import classNames from "classnames";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Row, Col, Card, CardHeader, CardBody, Table } from "reactstrap";
import { getPatientVotMissedReport } from "store/actions/patientVotMissedReport";

const DOTPatientWithVolunteerVotReport = ({
  patient,
  status,
  getPatientVotMissedReport,
}) => {
  const patientVotMissedReport = useSelector(
    (state) => state.patientVotMissedReport.patientVotMissedReport
  );
  const { id } = useParams();
  // console.log(patientVotMissedReport);

  useEffect(() => {
    getPatientVotMissedReport(id);
  }, []);

  if (status.secondLoading) {
    return <FullScreenLoading />;
  }

  return (
    <Card>
      <CardHeader className="border-0">
        <Row>
          <Col xs="6">
            <h3 className="mb-3">
              {" "}
              ({patient.patient?.name}) Missed VOT Report
            </h3>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Table
          className={classNames("app-table align-items-center table-fixed")}
        >
          <thead>
            <tr>
              <th rowSpan={3} className="">
                Total
              </th>
              <th rowSpan={3}>Visited</th>
              <th rowSpan={3}>Missed VOT</th>
              <th colSpan={3}>Missed targeted VOT doses</th>
            </tr>
            <tr>
              <th colSpan={4}>Missed doses (Frequency)</th>
            </tr>
            <tr>
              <th>&lt;10%</th>
              <th>10-50%</th>
              <th>&gt;50%</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{patientVotMissedReport?.must_taking_dots}</td>
              <td>{patientVotMissedReport?.taking_dots}</td>
              <td>{patientVotMissedReport?.miss_dose}</td>
              <td>
                {patientVotMissedReport?.result?.less_10
                  ? `(${patientVotMissedReport?.result?.less_10}) (${patientVotMissedReport?.result?.less_10_percentage}%)`
                  : "-"}
              </td>
              <td>
                {" "}
                {patientVotMissedReport?.result?.between_10_50
                  ? `(${patientVotMissedReport?.result?.between_10_50}) (${patientVotMissedReport?.result?.between_10_50_percentage}%)`
                  : "-"}
              </td>
              <td>
                {" "}
                {patientVotMissedReport?.result?.greater_50
                  ? `(${patientVotMissedReport?.result?.greater_50}) (${patientVotMissedReport?.result?.greater_50_percentage}%)`
                  : "-"}
              </td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  patient: store.patient,
});

export default connect(mapStateToProps, { getPatientVotMissedReport })(
  DOTPatientWithVolunteerVotReport
);
